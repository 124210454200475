import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
import { BehaviorSubject, interval } from 'rxjs';
import { skip } from 'rxjs/operators';
import { AuthService } from 'src/app/shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class CacheService {
  resetInProgress$ = new BehaviorSubject<boolean>(false);
  constructor(
    private authService: AuthService,
    public apollo: Apollo.Apollo
  ) {
    this.authService.organization$.pipe(skip(1)).subscribe(async () => {
      await this.clearStorage();
    });
  }

  async clearStorage() {
    this.resetInProgress$.next(true);
    // Clear cache
    await this.apollo.use('frontend').client.clearStore();
    this.resetInProgress$.next(false);
  }

  async debug() {
    console.log('Cache contents:');
    const cacheContents = this.apollo.use('frontend').client.cache.extract();
    console.log(cacheContents);
  }
}
